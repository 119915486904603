import { makeStyles } from '@material-ui/core/styles';

const style = (theme) => ({
  form: {
    width: '100%',
  },
  formField: {
    marginTop: theme.spacing(3),
  },
  addressResultsListSelectedIcon: {
    color: theme.palette.services.landline,
  },
  addressResultsLoading: {
    marginRight: theme.spacing(3),
  },

  contentContainer: {
    margin: theme.spacing(3, 0),
  },
  // form actions
  actionsContainer: {
    width: '100%',
    display: 'flex',
  },
  actionsConfirm: {
    marginTop: theme.spacing(3),
    paddingLeft: '40px',
    paddingRight: '40px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
});

export default makeStyles(style);
