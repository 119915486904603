import React from 'react';
import { Typography } from '@material-ui/core';
import { oneOfType, arrayOf, node } from 'prop-types';
import { useStyles } from './styles';
import {
  TickLargeContainedIcon,
  TickMediumIcon,
} from '@utilitywarehouse/react-icons';
import { useIsMobile } from 'app/components/modules/OrderSummary/ServicesTotal/Save/utils';

const GettingQuoteText = ({ children }) => {
  const classes = useStyles();
  const isMobile = useIsMobile();

  return (
    <div className={classes.content}>
      {!isMobile && <TickLargeContainedIcon className={classes.tick} />}
      {isMobile && <TickMediumIcon className={classes.tick}></TickMediumIcon>}
      <Typography variant="h3" component="h3" className={classes.text}>
        {children}
      </Typography>
    </div>
  );
};

GettingQuoteText.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
};

export { GettingQuoteText };
