import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  borderCustomer: {
    borderColor: theme.palette.secondary.main,
  },
  borderPartner: {
    borderColor: theme.palette.success.main,
  },
  borderSupporting: {
    borderColor: theme.palette.services.landline,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginLeft: theme.spacing(1),
  },
  infoSnackbar: {
    top: 84,
  },
  infoSnackbarAlert: {
    padding: theme.spacing(1),
  },
}));
