import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { CONNECTION_STATE } from 'redux/modules/RemoteSession/v2/constants';
import SectionCentered from 'modules/layout/SectionCentered';
import OrderedNumberList from 'modules/Shared/OrderedNumberList';
import Share from 'components/modules/Shared/Share';

const steps = [
  'Call your supporting Partner',
  'Ask them to sit front of a desktop, laptop or tablet computer',
  'Make sure you keep this browser window open',
];

const RemoteSupport = ({ accessCode, connection, error }) => {
  const connectionFailed =
    error ||
    (connection !== CONNECTION_STATE.CONNECTED &&
      connection !== CONNECTION_STATE.CONNECTING);
  return (
    <>
      <SectionCentered newLayout backgroundColor="white">
        {connectionFailed && (
          <Box flexGrow={1} py={4}>
            {<Alert severity="error">{error || connection}</Alert>}
          </Box>
        )}

        <Box flexGrow={1} py={4}>
          <Typography variant="h2" color="primary">
            Before you share your appointment
          </Typography>
          <OrderedNumberList data={steps} />
        </Box>
        <Box my={4}>
          <Typography variant="h3" color="primary">
            Your appointment code
          </Typography>
        </Box>
        <Box my={4}>
          <Typography>
            Ask your Supporting Partner to login into jtc on their browser and
            share this code with them to allow them to join your appointment
          </Typography>
        </Box>
        <Box my={4}>
          <Share value={accessCode} label="Copy code" />
        </Box>
      </SectionCentered>
    </>
  );
};

RemoteSupport.propTypes = {
  accessCode: PropTypes.string,
  connection: PropTypes.oneOf(Object.values(CONNECTION_STATE)),
  error: PropTypes.any,
};

export default RemoteSupport;
