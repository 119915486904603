import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ spacing, palette, breakpoints }) => ({
  container: {
    display: 'flex',
    gap: spacing(5),
    flexDirection: 'column',
    padding: spacing(3, 2),
    [breakpoints.up('sm')]: {
      padding: spacing(8, 3),
      gap: spacing(6),
    },
  },
  headerTitle: {
    color: palette.common.black,
    textAlign: 'center',
  },
  strokeBg: {
    stroke: palette.text.primary,
    strokeOpacity: '0.2',
  },
  strokeFill: {
    stroke: palette.line.primary,
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
  },
}));

export { useStyles };
