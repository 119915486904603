import React from 'react';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  alert: {
    padding: theme.spacing(2),
  },
}));

const AcceptConnectionDialog = ({
  open,
  actor,
  connectionFrom,
  onAccept,
  onDeny,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {actor.isCustomer()
          ? `${connectionFrom} would like to introduce you to UW`
          : 'Remote Appointment'}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {actor.isCustomer()
            ? `Your Partner will talk you through how UW works and show how it can benefit you`
            : `${connectionFrom} is about to connect...`}
        </DialogContentText>
        {actor.isCustomer() && (
          <Alert
            classes={{ root: classes.alert }}
            icon={<VisibilityOffIcon />}
            severity="error"
          >
            Don't worry, your Partner won't be able to view any sensitive
            information when you see this icon.
          </Alert>
        )}
      </DialogContent>
      <DialogActions>
        {actor.isCustomer() && (
          <Button variant="outlined" onClick={onDeny}>
            Deny request
          </Button>
        )}
        <Button
          autoFocus
          variant="contained"
          onClick={onAccept}
          data-test-id="remoteDialogAccept"
        >
          Accept request
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AcceptConnectionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  actor: PropTypes.object.isRequired,
  connectionFrom: PropTypes.string,
  onAccept: PropTypes.func.isRequired,
  onDeny: PropTypes.func.isRequired,
};

export default AcceptConnectionDialog;
