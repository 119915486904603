import { makeStyles } from '@material-ui/core/styles';
import { colorsCommon, colorsDark } from '@utilitywarehouse/colour-system';
const useStyles = makeStyles(({ spacing, breakpoints }) => ({
  content: {
    display: 'flex',
    gap: spacing(2),
    [breakpoints.up('sm')]: {
      alignItems: 'center',
      gap: spacing(3),
    },
  },
  text: {
    color: colorsCommon.brandWhite,
    fontSize: 22,
    [breakpoints.up('sm')]: {
      fontSize: 24,
    },
  },
  tick: {
    color: colorsDark.green800,
    flexShrink: 0,
  },
  transitionStyle: {
    opacity: '0',
  },
}));

export { useStyles };
