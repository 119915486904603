import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { InfoOutlined } from '@utilitywarehouse/partner-ui-icons';
import classnames from 'classnames';

const useStyles = makeStyles((theme) => ({
  alertMessage: {
    border: `2px solid ${theme.palette.warning.main}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(1, 2),
    margin: theme.spacing(2, 0),
    gap: theme.spacing(2, 2),
    backgroundColor: fade(theme.palette.warning.light, 0.25),
    display: 'inline-flex',
    alignItems: 'center',
  },
}));

const AlertMessage = ({ children, className }) => {
  const classes = useStyles();
  return (
    <div className={classnames(classes.alertMessage, className)}>
      <InfoOutlined />
      {children}
    </div>
  );
};

AlertMessage.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

export default AlertMessage;
