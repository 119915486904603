import { makeStyles } from '@material-ui/core/styles';
import { getSpacing } from 'app/lib/theme';

const styles = (theme) => {
  const spacing = getSpacing(theme);
  const iframeDimensions = { width: '100%', height: '655px' };

  return {
    error: {
      alignItems: 'center',
      backgroundColor: '#ddd',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      ...iframeDimensions,
    },
    iframe: {
      border: 'none',
      ...iframeDimensions,
    },
    button: {
      textAlign: 'center',
      width: '75%',
      margin: spacing(5),
    },
  };
};

export default makeStyles(styles);
