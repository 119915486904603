import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => {
  return {
    container: {
      position: 'absolute',
      left: -154,
      right: -42,
      width: 42,
      height: 42,
      zIndex: 999,
      pointerEvents: 'none',
    },
    cursor: {
      position: 'relative',
      top: -21,
      left: -21,
      width: 42,
      height: 42,
    },
    cursorIcon: {
      position: 'absolute',
      left: 0,
      top: 0,
      transform: 'translate3d(-1px, -2px, 0)',
    },
    cursorLabel: {
      position: 'absolute',
      left: '50%',
      top: theme.spacing(1),
      maxWidth: '21ch',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      padding: theme.spacing(0, 1),
      backgroundColor: 'red',
      borderRadius: 4,
      fontSize: 14,
      fontWeight: 600,
      color: theme.palette.common.white,
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.5)',
      pointerEvents: 'none',
    },
  };
});
