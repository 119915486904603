import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, CircularProgress, InputAdornment } from '@material-ui/core';
import TextField from 'modules/CustomerUI/TextField';
import useStyles from './styles';

const AddressResults = ({
  addresses,
  selectedAddress,
  onChange,
  loading,
  dataTestId,
  label = 'Your address',
}) => {
  const classes = useStyles();

  return (
    <TextField
      select
      fullWidth
      label={label}
      onChange={(e) => onChange(e.target.value)}
      value={selectedAddress}
      id={`AddressResults`}
      disabled={loading}
      dataTestId={dataTestId}
      InputProps={{
        endAdornment: loading && (
          <InputAdornment position="end">
            <CircularProgress
              size={24}
              thickness={5}
              color="secondary"
              className={classes.addressResultsLoading}
            />
          </InputAdornment>
        ),
      }}
    >
      {(!addresses || addresses.length === 0) && (
        <MenuItem>No addresses available</MenuItem>
      )}

      {addresses?.map((address) => (
        <MenuItem
          value={address.uuid}
          key={address.uuid}
          data-test-id={address.uuid}
        >
          {address.primaryText}
        </MenuItem>
      ))}
    </TextField>
  );
};

AddressResults.propTypes = {
  onChange: PropTypes.func.isRequired,
  addresses: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      primaryText: PropTypes.string.isRequired,
    })
  ),
  selectedAddress: PropTypes.string,
  loading: PropTypes.bool,
};

export default AddressResults;
