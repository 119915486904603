import { getBreakpoints, getSpacing } from 'app/lib/theme';

export default (theme) => {
  const spacing = getSpacing(theme);
  const breakpoints = getBreakpoints(theme);

  return {
    container: {
      display: 'flex',
      justifyContent: 'center',
      margin: 0,
      marginTop: spacing(3),
      marginBottom: spacing(6),
      [breakpoints.down('md')]: {
        marginTop: spacing(2),
        marginBottom: spacing(2),
      },
    },
  };
};
