import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import CircularProgress from '@material-ui/core/CircularProgress';

import useStyles from './styles';
import { Typography } from '@material-ui/core';

const Modal = ({ title, description }) => {
  const classes = useStyles();
  return (
    <Dialog disableBackdropClick disableEscapeKeyDown open>
      <DialogTitle>{title || 'Please wait'}</DialogTitle>
      <DialogContent className={classes.loaderDiv}>
        <div className={classes.loaderIconDiv}>
          <CircularProgress size={80} thickness={3} />
        </div>
        <Typography className={classes.loaderText}>{description}</Typography>
      </DialogContent>
    </Dialog>
  );
};

Modal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default Modal;
