import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

import Line from 'modules/Shared/LineSeparator';
import useStyles from './styles';
import { Typography } from '@material-ui/core';

const ErrorModal = ({ tryAgain, description, canBeResolved, reference }) => {
  const classes = useStyles();
  return (
    <Dialog open disableBackdropClick disableEscapeKeyDown>
      <DialogTitle>Error</DialogTitle>
      <DialogContent className={classes.loaderDiv}>
        <Typography className={classes.loaderText}>
          An error occurred completing your order
          {description && ` (${description})`}.
        </Typography>
        {!canBeResolved && (
          <Typography className={classes.loaderText}>
            Please contact sales on{' '}
            <a href="tel:0333 777 3 212">0333 777 3 212</a> to complete your
            order.
          </Typography>
        )}
        {reference && !canBeResolved && (
          <p className={classes.loaderText}>Reference: {reference}</p>
        )}
      </DialogContent>
      <DialogActions>
        {canBeResolved && (
          <div className={classes.actions}>
            <Line type="dotted" />
            <div className={classes.buttons}>
              <Button variant="outlined" onClick={tryAgain}>
                Try Again
              </Button>
            </div>
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
};

ErrorModal.propTypes = {
  canBeResolved: PropTypes.bool,
  description: PropTypes.string,
  reference: PropTypes.string,
  tryAgain: PropTypes.func,
};

export default ErrorModal;
