import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, FormControlLabel, Checkbox } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import clsx from 'classnames';
import RestrictedButton from 'redux/modules/RemoteSession/v2/RestrictedButton';
import isValidPostcode from 'redux/services/isValidPostcode';
import formatPostcode from 'redux/utils/formatPostcode';
import PostcodeInput from './PostcodeInput';
import AddressResults from './AddressResults';
import ManualEntry from './AddressManualEntry';
import useStyles from './styles';

const AddressPicker = ({
  children,
  onChangePostcode,
  onChangeAddressField,
  addressFields,
  onSelectAddress,
  selectedAddress,
  onSubmit,
  addresses,
  postcode,
  onSelectManualEntry,
  manualEntry = false,
  submitLabel = 'get quote',
  loading = false,
  fetchingAddressList = false,
  additionalFieldsValid = true,
  PostcodeInputProps = {},
  AddressResultsProps = {},
  PickerComponent = 'form',
  classes = {},
}) => {
  const internalClasses = useStyles();
  const [postcodeValid, setPostcodeValid] = useState(false);
  const addressesEmpty = addresses?.length === 0;

  useEffect(() => {
    if (postcode) {
      const formattedPostcode = formatPostcode(postcode);
      const valid = isValidPostcode(formattedPostcode);
      setPostcodeValid(valid);
    }
  }, [postcode]);

  const handlePostcodeChange = useCallback(
    (postcode) => {
      const formattedPostcode = formatPostcode(postcode);
      const valid = isValidPostcode(formattedPostcode);
      setPostcodeValid(valid);
      onChangePostcode(postcode);
    },
    [setPostcodeValid, onChangePostcode]
  );

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      let address;
      if (manualEntry || addressesEmpty) {
        address = {
          ...addressFields,
          postcode,
          uuid: '',
          addressNotListed: true,
        };
      } else {
        address = addresses.find((a) => a.uuid === selectedAddress);
      }

      onSubmit(address);
    },
    [
      manualEntry,
      addressesEmpty,
      onSubmit,
      addressFields,
      postcode,
      addresses,
      selectedAddress,
    ]
  );

  const addressSelected = !manualEntry && Boolean(selectedAddress);
  const manualAddressValid = manualEntry || addressesEmpty;
  const isFormValid =
    postcodeValid &&
    (addressSelected || manualAddressValid) &&
    additionalFieldsValid;

  const hasAddresses = addresses && addresses.length > 0;
  const showAddressPicker =
    (hasAddresses || fetchingAddressList) && !manualEntry;

  const showMissingCheckbox =
    (showAddressPicker || manualEntry) && !addressesEmpty;

  return (
    <PickerComponent
      onSubmit={handleSubmit}
      className={clsx(internalClasses.form, classes.root)}
    >
      <PostcodeInput
        onChange={handlePostcodeChange}
        postcode={postcode}
        error={!!postcode && !postcodeValid}
        errorMessage={!postcodeValid ? 'Enter a valid postcode' : null}
        {...PostcodeInputProps}
      />

      {showAddressPicker && (
        <AddressResults
          onChange={onSelectAddress}
          selectedAddress={selectedAddress}
          addresses={addresses}
          loading={fetchingAddressList}
          {...AddressResultsProps}
        />
      )}

      {addressesEmpty && (
        <Alert severity="warning" className={internalClasses.formField}>
          <Typography>
            We couldn't find any address for this postcode, please enter it
            manually
          </Typography>
        </Alert>
      )}

      {showMissingCheckbox && (
        <FormControlLabel
          label="My address is not listed"
          onChange={(_e, checked) => onSelectManualEntry(checked)}
          checked={manualAddressValid}
          disabled={addressesEmpty}
          control={<Checkbox />}
          className={internalClasses.formField}
        />
      )}

      {manualAddressValid && (
        <ManualEntry
          postcode={postcode}
          addressFields={addressFields}
          onChangeField={onChangeAddressField}
        />
      )}

      <div className={internalClasses.contentContainer}>{children}</div>

      {onSubmit && (
        <div className={internalClasses.actionsContainer}>
          <RestrictedButton
            id="AddressPickerUIConfirmAddress"
            dataTestId="AddressPickerUIConfirmAddress"
            variant="contained"
            size="medium"
            type="submit"
            disabled={!isFormValid || fetchingAddressList || loading}
            loading={loading}
            label={submitLabel}
            className={internalClasses.actionsConfirm}
          />
        </div>
      )}
    </PickerComponent>
  );
};

AddressPicker.propTypes = {
  onChangePostcode: PropTypes.func.isRequired,
  onChangeAddressField: PropTypes.func.isRequired,
  addressFields: PropTypes.objectOf(PropTypes.string),
  onSubmit: PropTypes.func,
  addresses: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
    })
  ),
  onSelectAddress: PropTypes.func.isRequired,
  selectedAddress: PropTypes.string,
  postcode: PropTypes.string,
  manualEntry: PropTypes.bool,
  onSelectManualEntry: PropTypes.func.isRequired,
  submitLabel: PropTypes.string,
  loading: PropTypes.bool,
  fetchingAddressList: PropTypes.bool,
  additionalFieldsValid: PropTypes.bool,
  PostcodeInputProps: PropTypes.objectOf(PropTypes.any),
  AddressResultsProps: PropTypes.objectOf(PropTypes.any),
  classes: PropTypes.objectOf(PropTypes.string),
  PickerComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
};

export default AddressPicker;
