export const TITLE = 'Your remote appointment code';
export const DESCRIPTION_1 =
  'Please ask your prospect to click on "Personalise your quote" in the presentation or ask them to navigate to uw.co.uk/join/remotely and enter the following code:';
export const DESCRIPTION_2 =
  'The application will start automatically, please wait.';
export const STEPS = [
  'Call your prospect on their landline or mobile',
  'Ask them to sit in front of a desktop, laptop or tablet computer',
  'Make sure you keep this browser window open',
];

export const INFO_FAILED = 'Something went wrong, please try again';
export const INFO_CONNECTING = 'Connecting...';
export const INFO_CONNECTED = 'Connected';
