import React from 'react';
import PropType from 'prop-types';
import Box from '@material-ui/core/Box';
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import * as copy from './copy';

function SecurityIcon({ tooltip }) {
  return (
    <Tooltip
      open={tooltip ? undefined : false}
      title={tooltip}
      aria-label={tooltip}
    >
      <Box
        p={1}
        borderRadius={8}
        bgcolor="error.main"
        color="text.primary"
        fontWeight="fontWeightBold"
        fontSize={14}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <VisibilityOffIcon />
        <Box>{copy.TITLE}</Box>
      </Box>
    </Tooltip>
  );
}

SecurityIcon.propTypes = {
  tooltip: PropType.bool,
};

export default SecurityIcon;
