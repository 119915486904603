import {
  API_CARD_TYPE_MAP,
  SUPPORTED_CARDS,
  spreedlyResponseDataKeys,
} from 'redux/modules/PaymentForm/constants';

const {
  FULL_NAME,
  MONTH,
  YEAR,
  CARD_TYPE,
  LAST_FOUR_DIGITS,
} = spreedlyResponseDataKeys;

export const formatData = (data) => ({
  [FULL_NAME]: data.full_name,
  [MONTH]: data.month.toString(),
  [YEAR]: data.year.toString(),
  [CARD_TYPE]: data.card_type,
  [LAST_FOUR_DIGITS]: data.last_four_digits,
});

const isSupportedCard = (card) =>
  SUPPORTED_CARDS.some(({ type }) => type === card);

const errorMessage = `The card is not supported. Supported cards are: ${SUPPORTED_CARDS.map(
  (cardType) => cardType.name
).join(', ')}.`;

export const postCardRequest = (
  token,
  data,
  postFundingPaymentCardRequest,
  openErrorGlobalDialog,
  setShowLoader
) => {
  const card = data[CARD_TYPE];

  if (isSupportedCard(card)) {
    const fullName = data[FULL_NAME];
    const apiCard = API_CARD_TYPE_MAP[card];
    const expiryDate =
      data[MONTH].length === 1
        ? `0${data[MONTH]}/${data[YEAR]}`
        : `${data[MONTH]}/${data[YEAR]}`;

    const lastFourDigits = data[LAST_FOUR_DIGITS];
    const cardNumber = lastFourDigits
      ? `#### #### #### ${lastFourDigits}`
      : null;
    postFundingPaymentCardRequest(
      token,
      fullName,
      apiCard,
      cardNumber,
      expiryDate
    );
  } else {
    setShowLoader(false);
    openErrorGlobalDialog(errorMessage);
  }
};
