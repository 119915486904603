export default (theme) => ({
  AddressNotListed: {
    marginTop: theme.spacing(2),
  },
  AddressNotListedFields: {
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 430,
    },
  },
  errorMessage: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 430,
    },
  },
});
