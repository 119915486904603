import React from 'react';
import PropTypes from 'prop-types';

const Cursor = ({ color, ...props }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" {...props}>
    <defs>
      <filter
        id="disabled-a"
        width="186.7%"
        height="165%"
        x="-43.3%"
        y="-32.5%"
        filterUnits="objectBoundingBox"
      >
        <feOffset dy="1" in="SourceAlpha" result="shadowOffsetOuter1" />
        <feGaussianBlur
          in="shadowOffsetOuter1"
          result="shadowBlurOuter1"
          stdDeviation="1"
        />
        <feColorMatrix
          in="shadowBlurOuter1"
          result="shadowMatrixOuter1"
          values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.65 0"
        />
        <feMerge>
          <feMergeNode in="shadowMatrixOuter1" />
          <feMergeNode in="SourceGraphic" />
        </feMerge>
      </filter>
    </defs>
    <g
      fill="none"
      fillRule="evenodd"
      filter="url(#disabled-a)"
      transform="rotate(-30 .17 .036)"
    >
      <polygon
        fill="#FFF"
        points="0 19.965 0 .605 14.036 14.651 5.51 14.651 5.327 14.801"
      />
      <polygon
        fill={color}
        points="1.21 3.388 1.21 16.94 4.802 13.468 4.996 13.3 11.09 13.31"
      />
    </g>
  </svg>
);

Cursor.propTypes = {
  color: PropTypes.string.isRequired,
};

export default Cursor;
