import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(({ palette }) => ({
  wrapper: {
    alignSelf: 'center',
  },
  strokeBg: {
    stroke: palette.text.primary,
    strokeOpacity: '0.2',
  },
  strokeFill: {
    stroke: palette.line.primary,
    strokeWidth: 2,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
  },
}));

export { useStyles };
