import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import Participants from './Participants';
import Actions from './Actions';
import useStyles from './styles';

const mapParticipants = (participants) => {
  return Object.entries(participants).reduce(
    (acc, [key, value]) => [
      ...acc,
      {
        type: key,
        ...value,
      },
    ],
    []
  );
};
const Controls = ({
  participants,
  driver,
  actor,
  onEndSession,
  onRequestControl,
  securePage,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className={clsx(classes.section, classes.participants)}>
          <Participants
            participants={mapParticipants(participants)}
            driver={driver}
            actor={actor}
          />
        </div>
        <div className={clsx(classes.section)}>
          <Actions
            hasControl={driver === actor}
            actor={actor}
            onRequestControl={onRequestControl}
            onEndSession={onEndSession}
            securePage={securePage}
          />
        </div>
      </div>
    </div>
  );
};

Controls.propTypes = {
  onRequestControl: PropTypes.func.isRequired,
  onEndSession: PropTypes.func.isRequired,
  participants: PropTypes.objectOf(PropTypes.object).isRequired,
  driver: PropTypes.string.isRequired,
  actor: PropTypes.string.isRequired,
  securePage: PropTypes.bool.isRequired,
};
export default Controls;
