import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import debounce from 'lodash/debounce';
import MenuItem from '@material-ui/core/MenuItem';
import TextFieldSelect from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';

import TextField from 'modules/Shared/NewTextField';
import { errors, errorMessages } from 'redux/modules/AddressPicker/constants';
import styles from './styles';

export class AddressPickerResults extends Component {
  constructor() {
    super();
    this.fetchAddresses = this.fetchAddresses.bind(this);
    this.onChangePostcode = this.onChangePostcode.bind(this);
    this.fetchAddressDebounced = debounce(this.fetchAddresses, 250);
    this.selectAddress = this.selectAddress.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { selectedAddressId, getAddressDetailsRequest } = this.props;

    if (selectedAddressId !== prevProps.selectedAddressId) {
      getAddressDetailsRequest(selectedAddressId);
    }
  }

  onChangePostcode(e) {
    const postcode = e.target.value;
    this.props.updatePostcodeField(postcode);

    if (postcode.length > 3) {
      this.fetchAddressDebounced(postcode);
    }
  }

  fetchAddresses() {
    const { getAddressesRequest, postcode } = this.props;

    getAddressesRequest(postcode);
  }

  selectAddress({ target: { value } }) {
    this.props.selectAddress(value);
    this.props.getAddressDetailsRequest(value);
  }

  render() {
    const {
      postcode,
      addresses,
      addressesError,
      selectedAddressId,
      addressesFetching,
      id,
      label,
      classes,
      autoFocus = true,
    } = this.props;

    let errorText = null;

    if (addressesError && addressesError !== errors.ERR_CONNECTION) {
      errorText = errorMessages[addressesError];
    }

    return (
      <div className={classes.base}>
        <TextField
          autoFocus={autoFocus}
          tabIndex="0"
          id={id ? [id] : 'AddressPickerUIPostcode'}
          dataTestId="AddressPickerUIPostcode"
          value={postcode}
          onChange={this.onChangePostcode}
          onKeyUp={this.onChangePostcode}
          errorMessage={errorText}
          error={!isEmpty(errorText)}
          label={label ? [label] : 'Type in your postcode'}
          placeholder="NW9 6EU"
          fullWidth
        />

        {addressesFetching && (
          <LinearProgress
            className={classes.linearProgress}
            mode="indeterminate"
            color="secondary"
          />
        )}
        {addresses.length > 0 && (
          <TextFieldSelect
            select
            variant="filled"
            label="Select your address"
            tabIndex="0"
            id="AddressPickerUIAddressID"
            data-test-id="AddressPickerUIAddressID"
            value={selectedAddressId}
            onChange={this.selectAddress}
            fullWidth
            className={classes.SelectFieldUI}
          >
            {addresses.map((a) => (
              <MenuItem value={a.uuid} key={a.uuid} data-test-id={a.uuid}>
                {a.primaryText}
              </MenuItem>
            ))}
          </TextFieldSelect>
        )}
      </div>
    );
  }
}

AddressPickerResults.defaultProps = {
  postcode: '',
  addresses: [],
  addressesError: null,
};

AddressPickerResults.propTypes = {
  addresses: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
    })
  ),
  postcode: PropTypes.string,
  selectAddress: PropTypes.func.isRequired,
  getAddressesRequest: PropTypes.func.isRequired,
  getAddressDetailsRequest: PropTypes.func.isRequired,
  updatePostcodeField: PropTypes.func.isRequired,
  addressesFetching: PropTypes.bool.isRequired,
  addressesError: PropTypes.string,
  classes: PropTypes.object.isRequired,
  autoFocus: PropTypes.bool,
};

export default withStyles(styles)(AddressPickerResults);
