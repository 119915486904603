import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import { Typography } from '@material-ui/core';
import { ActorType } from 'app/redux/modules/RemoteSession/v2/actor';
import useStyles from './styles';

const Participants = ({ participants, driver, actor }) => {
  const classes = useStyles();

  return (
    <>
      {participants.map((participant) => (
        <div
          key={participant.type}
          className={clsx(classes.participant, {
            [classes.participantPartner]:
              participant.type === ActorType.PARTNER,
            [classes.participantSupporting]:
              participant.type === ActorType.SUPPORTING_PARTNER,
            [classes.participantCustomer]:
              participant.type === ActorType.CUSTOMER,
            [classes.participantHasControl]: participant.type === driver,
          })}
        >
          <span>
            {participant.type === actor && <Typography>Me</Typography>}
            {participant.type !== actor && (
              <Typography>
                <strong>
                  {participant.name
                    ? participant.name
                        .split(' ')
                        .map((s) => s.slice(0, 1).toUpperCase())
                    : 'C'}
                </strong>
              </Typography>
            )}
          </span>
        </div>
      ))}
    </>
  );
};

Participants.propTypes = {
  participants: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      name: PropTypes.string,
    })
  ).isRequired,
  driver: PropTypes.string.isRequired,
  actor: PropTypes.string.isRequired,
};

export default Participants;
