import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import { ButtonBase } from '@material-ui/core';
import { EndCall, ShareScreen } from '@utilitywarehouse/partner-ui-icons';
import { ActorType } from 'redux/modules/RemoteSession/v2/actor';
import useStyles from './styles';

const Actions = ({
  hasControl,
  onRequestControl,
  onEndSession,
  securePage,
  actor,
}) => {
  const classes = useStyles();

  return (
    <>
      <ButtonBase
        className={clsx(classes.action, classes.actionOutlined, {
          [classes.actionOutlinedActive]: hasControl,
        })}
        onClick={onRequestControl}
        disabled={hasControl || securePage}
        data-test-id="remoteControlsTakeControl"
      >
        <ShareScreen />
      </ButtonBase>
      <ButtonBase
        className={clsx(classes.action, classes.actionFilled)}
        onClick={onEndSession}
        disabled={actor === ActorType.SUPPORTING_PARTNER}
      >
        <EndCall />
      </ButtonBase>
    </>
  );
};

Actions.propTypes = {
  hasControl: PropTypes.bool.isRequired,
  securePage: PropTypes.bool.isRequired,
  onRequestControl: PropTypes.func.isRequired,
  onEndSession: PropTypes.func.isRequired,
  actor: PropTypes.string.isRequired,
};

export default Actions;
