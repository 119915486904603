import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import TextField from 'modules/Shared/NewTextField';
import Space from 'components/modules/Shared/Space';
import ErrorMessage from 'modules/Shared/ErrorMessage';
import { errors, errorMessages } from 'redux/modules/AddressPicker/constants';
import styles from './styles';

const isAddressNotFetchedError = (error) => {
  return error === errors.ERR_CONNECTION || error === errors.ERR_NOT_FOUND;
};

export const AddressNotListed = ({
  addressNotListed,
  toggleAddressNotListed,
  postcode,
  updateFieldAddressNotListed,
  isValidForm,
  addressesError,
  addressDetailsNotListed,
  addressesFetching,
  classes,
}) => {
  const {
    flatNumber,
    buildingName,
    buildingNumber,
    street,
    town,
  } = addressDetailsNotListed;

  const addressListed = !addressNotListed;
  const isValidPostCode =
    addressListed && !addressesError && postcode.length > 3;
  const showNotListedOption =
    ((!addressesFetching && isValidPostCode) || addressNotListed) &&
    !isAddressNotFetchedError(addressesError);

  return (
    <div className={classes.AddressNotListed}>
      {showNotListedOption && (
        <FormControlLabel
          label="My address is not listed"
          onChange={(_, checked) => {
            toggleAddressNotListed(checked);
          }}
          checked={addressNotListed}
          control={<Checkbox />}
        />
      )}

      {isAddressNotFetchedError(addressesError) && (
        <ErrorMessage
          data-test-id="AddressPickerConnErr"
          message={errorMessages[addressesError]}
          className={classes.errorMessage}
        />
      )}

      {(addressNotListed || isAddressNotFetchedError(addressesError)) && (
        <div className={classes.AddressNotListedFields}>
          <TextField
            dataTestId="AddressPickerUIHousenumber"
            label="Flat Number"
            fullWidth
            value={flatNumber}
            onChange={(e, v) => {
              updateFieldAddressNotListed(v, 'flatNumber');
            }}
          />
          <TextField
            dataTestId="AddressPickerUIAddressline1"
            label="Building Name"
            fullWidth
            value={buildingName}
            onChange={(e, v) => {
              updateFieldAddressNotListed(v, 'buildingName');
            }}
          />
          <TextField
            dataTestId="AddressPickerUIAddressline2"
            label="Building Number"
            fullWidth
            required
            value={buildingNumber}
            onChange={(e, v) => {
              updateFieldAddressNotListed(v, 'buildingNumber');
            }}
          />
          <TextField
            dataTestId="AddressPickerUIStreet"
            label="Street"
            fullWidth
            required
            value={street}
            onChange={(e, v) => {
              updateFieldAddressNotListed(v, 'street');
            }}
          />
          <TextField
            dataTestId="AddressPickerUICity"
            label="City/Town"
            fullWidth
            required
            value={town}
            onChange={(e, v) => {
              updateFieldAddressNotListed(v, 'town');
            }}
          />
          <TextField
            dataTestId="AddressPostcode"
            label="Postcode"
            fullWidth
            disabled
            value={postcode.toUpperCase()}
            onChange={() => {}}
          />
          {isValidForm.details && (
            <div data-test-id="AddressPickerValidationErr">
              <Space height="15px" />
              <ErrorMessage message={isValidForm.details} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

AddressNotListed.defaultProps = {
  addressesError: null,
};

AddressNotListed.propTypes = {
  addressDetailsNotListed: PropTypes.shape({
    housename: PropTypes.string,
    addressLine1: PropTypes.string,
    addressLine2: PropTypes.string,
    city: PropTypes.string,
    county: PropTypes.string,
  }).isRequired,
  addressNotListed: PropTypes.bool.isRequired,
  toggleAddressNotListed: PropTypes.func.isRequired,
  postcode: PropTypes.string.isRequired,
  updateFieldAddressNotListed: PropTypes.func.isRequired,
  isValidForm: PropTypes.shape({
    details: PropTypes.string,
  }).isRequired,
  addressesError: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AddressNotListed);
