import React from 'react';
import { Paper, Fade } from '@material-ui/core';
import { useStyles } from './styles';
import { GettingQuoteText } from '../GettingQuoteText';

const Banner = () => {
  const classes = useStyles();

  return (
    <Paper classes={{ root: classes.paper }}>
      <div className={classes.content}>
        <Fade in timeout={2000} style={{ transitionDelay: '1000ms' }}>
          <div>
            <GettingQuoteText>
              {' '}
              <span className={classes.highlight}>One</span> bill,{' '}
              <span className={classes.highlight}>one</span> account,{' '}
              <span className={classes.highlight}>one</span> contact number...
            </GettingQuoteText>
          </div>
        </Fade>
        <Fade in timeout={2000} style={{ transitionDelay: '4000ms' }}>
          <div>
            <GettingQuoteText>
              Add more services to{' '}
              <span className={classes.highlight}>save more</span> on your
              energy...
            </GettingQuoteText>
          </div>
        </Fade>
        <Fade in timeout={2000} style={{ transitionDelay: '7000ms' }}>
          <div>
            <GettingQuoteText>
              Earn up to <span className={classes.highlight}>10% cashback</span>{' '}
              with our Cashback Card...
            </GettingQuoteText>
          </div>
        </Fade>
      </div>
    </Paper>
  );
};

Banner.propTypes = {};

export { Banner };
