import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { formatPrice } from 'redux/utils/formatter.js';
import { makeStyles } from '@material-ui/core/styles';
import { price } from 'app/constants/propTypes';

const useStyles = makeStyles((theme) => ({
  heading: {
    margin: theme.spacing(2, 0),
  },
}));

const PaymentInformation = ({ deposits, paymentAmount }) => {
  const classes = useStyles();
  return (
    <>
      <Typography
        variant="h3"
        className={classes.heading}
      >{`Make your payment of ${formatPrice(
        deposits ? deposits.total : paymentAmount
      )} to complete your order`}</Typography>{' '}
      <Typography>{`Before we can complete your order, we need to take a deposit of ${formatPrice(
        deposits ? deposits.total : paymentAmount
      )}. You'll see this added to your UW account balance after you've signed up,
      and you'll be able to withdraw it back to your bank account once you've been
      with us for 12 months.`}</Typography>
    </>
  );
};

PaymentInformation.propTypes = {
  deposits: PropTypes.shape({
    total: price,
  }),
  paymentAmount: price,
};

export default PaymentInformation;
