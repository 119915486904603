import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Card, Typography } from '@material-ui/core';
import styles from './styles';

const AddressPickerWrapper = ({
  classes,
  title = 'Your address',
  children,
}) => (
  <Card className={classes.root}>
    <Typography className={classes.title} variant="h3">
      {title}
    </Typography>
    {children}
  </Card>
);

AddressPickerWrapper.propTypes = {
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
};

export default withStyles(styles)(AddressPickerWrapper);
