import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  loaderDiv: {
    maxWidth: 800,
    padding: theme.spacing(2),
    margin: theme.spacing(2),
  },
  loaderText: {
    textAlign: 'center',
  },
  loaderIconDiv: {
    margin: '0 auto',
    width: 80,
  },
  formError: {
    padding: theme.spacing(2.5),
  },
  actions: {
    padding: theme.spacing(1.5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row-reverse',
  },
}));
