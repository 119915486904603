import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SpreedlyTokenisationComponent } from '@utilitywarehouse/spreedly-tokenisation-component';

import { ENVIRONMENT } from 'config';
import SectionCentered from 'modules/layout/SectionCentered';
import NavigationPane from 'modules/Shared/NavigationButton/NavigationPane';
import Modal from './Modal/modal';
import ErrorModal from './Modal/errorModal';
import { formatData, postCardRequest } from './helpers';
import useStyles from './styles';
import PaymentInformation from './PaymentInformation';
import { price } from 'app/constants/propTypes';

const PaymentForm = ({
  completeState: { error, loading, canBeResolved, message },
  applicationId,
  tryAgain,
  openErrorGlobalDialog,
  postFundingPaymentCardRequest,
  deposits,
  paymentAmount,
}) => {
  const classes = useStyles();

  const [showLoader, setShowLoader] = useState(false);

  const formSubmitHandler = (spreedlyResponse) => {
    const token = spreedlyResponse.token;
    setShowLoader(true);
    const data = formatData(spreedlyResponse.spreedlyResponse);
    postCardRequest(
      token,
      data,
      postFundingPaymentCardRequest,
      openErrorGlobalDialog,
      setShowLoader
    );
  };

  return (
    <>
      <SectionCentered>
        {(showLoader || loading) && !error && (
          <Modal description="We're processing your payment." />
        )}

        {error && canBeResolved !== true && (
          <ErrorModal
            tryAgain={tryAgain}
            description={message}
            canBeResolved={canBeResolved}
            reference={applicationId}
          />
        )}
        <div className={classes.holder}>
          <PaymentInformation
            deposits={deposits}
            paymentAmount={paymentAmount}
          />
          <div className={classes.inputHolder}>
            <SpreedlyTokenisationComponent
              spreedlyEnv={ENVIRONMENT === 'production' ? 'prod' : 'dev'}
              buttonLabel="Pay and complete order"
              onReturnSpreedlyResponse={formSubmitHandler}
              sx={{
                marginTop: '32px',
              }}
              dataTestId="PaymentFormCardInput"
            />
          </div>
        </div>
      </SectionCentered>
      <NavigationPane back={tryAgain} />
    </>
  );
};

PaymentForm.propTypes = {
  openErrorGlobalDialog: PropTypes.func.isRequired,
  applicationId: PropTypes.string.isRequired,
  postFundingPaymentCardRequest: PropTypes.func.isRequired,
  tryAgain: PropTypes.func.isRequired,
  completeState: PropTypes.shape({
    error: PropTypes.bool,
    loading: PropTypes.bool,
    canBeResolved: PropTypes.bool,
    message: PropTypes.string,
  }).isRequired,
  deposits: PropTypes.shape({
    total: price,
  }),
  paymentAmount: price,
};

export default PaymentForm;
