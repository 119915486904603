import React from 'react';
import SectionCentered from 'modules/layout/SectionCentered';
import TitleBar from 'modules/Shared/TitleBar';
import NavigationPane from 'modules/Shared/NavigationButton/NavigationPane';
import ErrorModal from '../Modal/errorModal';
import Iframe from './iframe';
import { API_URL } from 'config';
import useStyles from './styles';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { formatPrice } from 'app/redux/utils/formatter';

const buildIframeURL = (applicationId) =>
  `${API_URL}/sales/application/${applicationId}/payment/cnp/form`;

const CardNotPresentForm = ({
  error,
  appError,
  message,
  canBeResolved,
  tryAgain,
  applicationId,
  postCNPPaymentRequest,
  postCNPPaymentFailure,
  paymentAmount,
}) => {
  const classes = useStyles();

  return (
    <>
      <SectionCentered>
        {error && canBeResolved !== true && (
          <ErrorModal
            tryAgain={tryAgain}
            description={message}
            canBeResolved={canBeResolved}
            reference={applicationId}
          />
        )}

        <form id="card-payment-form">
          <TitleBar>
            Payment <b>Details</b>
          </TitleBar>
          <Typography variant="h3">
            {`Payment amount: ${formatPrice(paymentAmount)}`}
          </Typography>
          {appError && (
            <div className={classes.error}>
              <Button
                classes={{ root: classes.button }}
                variant="contained"
                onClick={tryAgain}
              >
                Try Again
              </Button>
            </div>
          )}
          {!error && !appError && (
            <Iframe
              url={buildIframeURL(applicationId)}
              onSubmit={postCNPPaymentRequest}
              onError={postCNPPaymentFailure}
            />
          )}
        </form>
      </SectionCentered>
      <NavigationPane back={tryAgain} />
    </>
  );
};

CardNotPresentForm.propTypes = {
  error: PropTypes.bool.isRequired,
  appError: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  canBeResolved: PropTypes.bool,
  tryAgain: PropTypes.func,
  applicationId: PropTypes.string.isRequired,
  postCNPPaymentRequest: PropTypes.func,
  postCNPPaymentFailure: PropTypes.func,
};

export default CardNotPresentForm;
