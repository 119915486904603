import { getBreakpoints, getSpacing } from 'app/lib/theme';

export default (theme) => {
  const spacing = getSpacing(theme);
  const breakpoints = getBreakpoints(theme);

  return {
    container: {
      marginBottom: spacing(6),
      [breakpoints.down('md')]: {
        marginBottom: spacing(2),
        padding: spacing(4),
      },
    },
    formContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      [breakpoints.down('xs')]: {
        flexDirection: 'column',
      },
    },
    inputContainer: {
      display: 'flex',
      flex: 0.48,
      marginTop: spacing(2),
    },
    button: {
      height: '100%',
      marginTop: 26,
      marginLeft: spacing(4),
      [breakpoints.down('xs')]: {
        marginLeft: 0,
      },
    },
    pos: {
      marginBottom: spacing(2),
    },
    alertRoot: {
      marginTop: 32,
    },
    alertBg: {
      backgroundColor: '#f5f0f8',
    },
    alertMessage: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    visibilityButton: {
      padding: 0,
    },
  };
};
