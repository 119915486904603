import React from 'react';
import PropTypes from 'prop-types';
import AddressPickerForm from './AddressPicker';
import AddressPickerWrapper from './AddressPickerWrapper';

const AddressPicker = ({
  hideContainer = false,
  title,
  classes = {},
  ...props
}) => {
  if (hideContainer) {
    return (
      <div className={classes.unwrappedRoot}>
        <AddressPickerForm {...props} />
      </div>
    );
  }

  return (
    <AddressPickerWrapper title={title}>
      <AddressPickerForm {...props} />
    </AddressPickerWrapper>
  );
};

AddressPicker.propTypes = {
  validatePostcodeField: PropTypes.func.isRequired,
  updatePostcodeField: PropTypes.func.isRequired,
  addressNotListed: PropTypes.bool.isRequired,
  postcode: PropTypes.string,
  addresses: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
    })
  ),
  isValidForm: PropTypes.shape({
    isValid: PropTypes.bool,
  }),
  isValidPostcode: PropTypes.bool,
  getAddressDetailsRequest: PropTypes.func.isRequired,
  getAddressesRequest: PropTypes.func.isRequired,
  selectedAddressId: PropTypes.string,
  selectAddress: PropTypes.func.isRequired,
  addressesFetching: PropTypes.bool.isRequired,
  addressesError: PropTypes.string,
  isRequestInProgress: PropTypes.bool.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  updateAnalyticsPage: PropTypes.func,
  reset: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  title: PropTypes.string,
  hideContainer: PropTypes.bool,
};

export default AddressPicker;
