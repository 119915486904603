import { makeStyles } from '@material-ui/core/styles';
import {
  fade,
  darken,
  lighten,
} from '@material-ui/core/styles/colorManipulator';
const style = (theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0 auto',
  },
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: theme.spacing(10),
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(5),
    borderColor: theme.palette.common.white,
    border: '1px solid',
    backgroundColor: fade(theme.palette.text.primary, 0.95),
  },
  section: {
    display: 'flex',
    flexGrow: '1',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '100%',
    padding: theme.spacing(0, 2),
    '& + &': {
      borderLeft: `1px solid ${fade(theme.palette.common.white, 0.2)}`,
    },
  },
  participants: {
    flexDirection: 'row-reverse',
  },
  participant: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: theme.spacing(2.5),
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.primary,
    ['& + &']: {
      marginInlineEnd: `${theme.spacing(-0.5)}px`,
    },
  },
  participantPartner: {
    backgroundColor: darken(theme.palette.success.main, 0.35),
    '&$participantHasControl': {
      backgroundColor: theme.palette.success.main,
    },
  },
  participantCustomer: {
    backgroundColor: darken(theme.palette.secondary.main, 0.35),
    '&$participantHasControl': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  participantSupporting: {
    backgroundColor: darken(theme.palette.services.landline, 0.35),
    '&$participantHasControl': {
      backgroundColor: theme.palette.services.landline,
    },
  },
  participantHasControl: {
    zIndex: '1000',
  },

  actions: {},
  action: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    borderRadius: theme.spacing(2.5),
    color: theme.palette.common.white,

    '& + &': {
      marginInlineStart: `${theme.spacing(2)}px`,
    },
  },

  actionOutlined: {
    border: `2px solid ${theme.palette.common.white}`,
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
  },
  actionOutlinedActive: {
    backgroundColor: theme.palette.common.white,
    border: 'none',
    color: theme.palette.text.primary,
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.85),
    },
  },
  actionFilled: {
    backgroundColor: theme.palette.services.insurance,
    '&:hover': {
      backgroundColor: lighten(theme.palette.services.insurance, 0.25),
    },
  },
});
export default makeStyles(style);
