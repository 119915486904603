import React from 'react';

import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core';
import SectionCentered from 'modules/layout/SectionCentered';

import * as copy from './copy';
import styles from './styles';

const RemotePartnerNotice = ({ classes }) => {
  return (
    <SectionCentered sectionCenteredClasses={{ root: classes.container }}>
      <Alert variant="outlined" severity="info">
        {copy.DETAILS}
      </Alert>
    </SectionCentered>
  );
};

RemotePartnerNotice.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RemotePartnerNotice);
