import { fade } from '@material-ui/core/styles/colorManipulator';

export default (theme) => ({
  root: {
    padding: theme.spacing(4),
    border: `2px solid ${fade(theme.palette.primary.main, 0.2)}`,
    marginBottom: theme.spacing(2),
    maxWidth: 900,
  },
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 430,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
  },
  title: {
    color: theme.palette.primary.main,
    paddingBottom: theme.spacing(2),
  },
  submitButton: {
    marginLeft: 'auto',
  },
  resetButton: {
    marginBottom: theme.spacing(1),
    marginRight: 0,
    [theme.breakpoints.up('sm')]: {
      marginRight: theme.spacing(1),
      marginBottom: 0,
    },
  },
});
