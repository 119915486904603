import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '@utilitywarehouse/colour-system';
import clsx from 'classnames';
import { Alert as MuiAlert } from '@material-ui/lab';
import { fade } from '@material-ui/core/styles/colorManipulator';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 800,
    padding: theme.spacing(2, 2, 2, 2),
    backgroundColor: fade(theme.palette.warning.main, 0.1),
    borderWidth: 1,
    borderRadius: theme.spacing(1),
    color: theme.palette.text.primary,
    fontSize: 16,

    '&[class*="outlinedSuccess"]': {
      backgroundColor: colors.green50,
      borderColor: colors.green500,
      color: colors.green900,
      '& [class*="-icon"]': {
        color: colors.green700,
      },
    },
    '&[class*="outlinedInfo"]': {
      backgroundColor: colors.cyan50,
      borderColor: colors.cyan500,
      color: colors.cyan700,
      '& [class*="-icon"]': {
        color: colors.cyan700,
      },
    },
    '&[class*="outlinedWarning"]': {
      backgroundColor: colors.gold50,
      borderColor: colors.gold500,
      color: colors.gold900,
      '& [class*="-icon"]': {
        color: colors.gold700,
      },
    },
    '&[class*="outlinedError"]': {
      backgroundColor: colors.red50,
      borderColor: colors.red500,
      color: colors.red900,
      '& [class*="-icon"]': {
        color: colors.red700,
      },
    },
  },
}));

export const Alert = ({ className, severity, children }) => {
  const classes = useStyles();

  return (
    <MuiAlert
      severity={severity}
      variant="outlined"
      className={clsx(classes.root, className)}
    >
      {children}
    </MuiAlert>
  );
};

Alert.PropTypes = {
  className: PropTypes.string,
  severity: PropTypes.oneOf(['success', 'info', 'warning', 'error']),
  children: PropTypes.node.isRequired,
};
