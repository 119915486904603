import React from 'react';
import parseUKAddress from 'redux/utils/UKAddressParser';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import styles from './styles';

const AddressLines = ({ address, title, actions = null, classes }) => {
  const addressLines = parseUKAddress(address, false);

  return (
    <div className={classes.addressContainer}>
      <div className={classes.addressCopy}>
        {title}
        <Typography variant="body2" component="p">
          {addressLines.map((line, i) => (
            <span className={classes.lines} key={i}>
              {line}
            </span>
          ))}
        </Typography>
      </div>
      <div className={classes.actionsContainer}>{actions}</div>
    </div>
  );
};

export default withStyles(styles)(AddressLines);
