import React from 'react';
import PropTypes from 'prop-types';
import { Box, Link, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

import { APP_URL } from 'config';
import { CONNECTION_STATE } from 'redux/modules/RemoteSession/v2/constants';
import OrderedNumberList from 'modules/Shared/OrderedNumberList';
import SectionCentered from 'modules/layout/SectionCentered';
import Share from 'components/modules/Shared/Share';

import * as copy from './copy';

const Options = ({ accessCode, connection, error }) => {
  const link =
    process.env.NODE_ENV === 'production'
      ? `${new URL(APP_URL).origin}/share?remote_code=${accessCode}`
      : `${APP_URL}/remotely?remote_code=${accessCode}`;
  const connectionFailed =
    error ||
    (connection !== CONNECTION_STATE.CONNECTED &&
      connection !== CONNECTION_STATE.CONNECTING);

  return (
    <>
      <SectionCentered newLayout backgroundColor="white">
        {connectionFailed && (
          <Box flexGrow={1} py={4}>
            {<Alert severity="error">{copy.INFO_FAILED}</Alert>}
          </Box>
        )}
        <Box flexGrow={1} py={4}>
          <Typography variant="h2" color="primary">
            Before you share your appointment
          </Typography>
          <OrderedNumberList data={copy.STEPS} />
        </Box>
      </SectionCentered>
      <SectionCentered newLayout color="white">
        <Box flexGrow={1} my={4}>
          <Typography variant="h2" color="primary">
            {copy.TITLE}
          </Typography>
          <Box my={4}>
            <Typography>
              Your prospect will need this code to access your appointment if
              you cannot share a link.
            </Typography>
            <Typography>
              You will also need to share this code with your supporting
              Partner, if you have one.
            </Typography>
          </Box>
          <Box my={4}>
            <Box my={4}>
              <Share value={accessCode} label="Copy code" />
            </Box>
          </Box>
        </Box>
        <Box flexGrow={1} my={4}>
          <Typography variant="h2" color="primary">
            Your remote appointment link
          </Typography>
          <Box my={4}>
            <Typography>
              Ask your prospect to visit{' '}
              <Link underline="always">uw.co.uk/share</Link> on their browser
              and enter your appointment code -{' '}
              <Typography variant="subtitle2" component="span">
                {accessCode}
              </Typography>{' '}
              - when asked. Alternatively, they can simply visit this link:
            </Typography>
          </Box>
          <Typography variant="h3" color="primary">
            <Share id="remote-options-url" value={link} label="Copy link" />
          </Typography>
        </Box>
      </SectionCentered>
    </>
  );
};

Options.propTypes = {
  accessCode: PropTypes.string,
  connection: PropTypes.oneOf(Object.values(CONNECTION_STATE)),
  error: PropTypes.any,
};

export default Options;
