import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import debounce from 'lodash/debounce';
import Button from '@material-ui/core/Button';
import RestrictedButton from 'redux/modules/RemoteSession/v2/RestrictedButton';
import Space from 'components/modules/Shared/Space';
import formatPostcode from 'redux/utils/formatPostcode';
import { sanitiseText } from 'redux/utils/sanitise';
import AddressResults from './Results';
import AddressNotListedUI from './NotListed';
import styles from './styles';

class AddressPickerUI extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.updatePostcodeFieldAndDebouncedValidate = this.updatePostcodeFieldAndDebouncedValidate.bind(
      this
    );
  }

  updatePostcodeFieldAndDebouncedValidate(postcode) {
    const { updatePostcodeField, validatePostcodeField } = this.props;

    updatePostcodeField(sanitiseText(postcode));
    debounce(validatePostcodeField, 500)();
  }

  confirmAddressClickHandler() {
    const {
      update,
      addressDetails,
      selectedAddressId,
      addressNotListed,
      addressDetailsNotListed,
      reset,
      postcode,
    } = this.props;

    const address = addressNotListed
      ? addressDetailsNotListed
      : addressDetails[selectedAddressId || Object.keys(addressDetails)[0]];

    update(
      {
        ...address,
        addressNotListed,
        postcode: formatPostcode(postcode),
      },
      'currentAddressSelected'
    );
    reset();
  }

  render() {
    const {
      addressNotListed,
      postcode,
      addresses,
      isValidForm,
      isValidPostcode,
      getAddressDetailsRequest,
      getAddressesRequest,
      selectedAddressId,
      selectAddress,
      addressesFetching,
      addressesError,
      isRequestInProgress,
      id,
      label,
      updateAnalyticsPage,
      reset,
      classes,
      toggleAddressNotListed,
      addressDetailsNotListed,
      updateFieldAddressNotListed,
      autoFocus,
      showBackButton = true,
      continueDisabled = false,
      children,
    } = this.props;

    let { submitButtonText, backButtonText } = this.props;

    submitButtonText = submitButtonText || 'Confirm address';
    backButtonText = backButtonText || 'Reset';

    return (
      <>
        {!addressNotListed && (
          <AddressResults
            getAddressDetailsRequest={getAddressDetailsRequest}
            getAddressesRequest={getAddressesRequest}
            updatePostcodeField={this.updatePostcodeFieldAndDebouncedValidate}
            selectAddress={selectAddress}
            selectedAddressId={selectedAddressId}
            addresses={addresses}
            addressesFetching={addressesFetching}
            addressesError={addressesError}
            postcode={postcode}
            id={id}
            label={label}
            autoFocus={autoFocus}
          />
        )}

        {postcode && (
          <div>
            <AddressNotListedUI
              addressNotListed={addressNotListed}
              toggleAddressNotListed={toggleAddressNotListed}
              postcode={postcode}
              updateFieldAddressNotListed={updateFieldAddressNotListed}
              isValidForm={isValidForm}
              addressesError={addressesError}
              addressDetailsNotListed={addressDetailsNotListed}
              addressesFetching={addressesFetching}
            />
            <Space height="20px" />
            {children}
            <div className={classes.buttonWrapper}>
              {showBackButton && isValidPostcode && (
                <Button
                  data-test-id="AddressPickerUIBack"
                  variant="outlined"
                  color="secondary"
                  size="medium"
                  label={backButtonText}
                  onClick={reset}
                  className={classes.resetButton}
                >
                  {backButtonText}
                </Button>
              )}
              <RestrictedButton
                id="AddressPickerUIConfirmAddress"
                dataTestId="AddressPickerUIConfirmAddress"
                variant="contained"
                size="medium"
                onClick={() => {
                  this.confirmAddressClickHandler();
                  updateAnalyticsPage();
                }}
                disabled={
                  !isValidPostcode ||
                  isRequestInProgress ||
                  continueDisabled ||
                  (!isValidForm.isValid && addressNotListed)
                }
                label={submitButtonText}
              />
            </div>
          </div>
        )}
      </>
    );
  }
}

AddressPickerUI.defaultProps = {
  postcode: '',
  addresses: [],
  selectedAddressId: null,
  id: null,
  label: '',
  addressesError: null,
  updateAnalyticsPage: () => {},
  isValidForm: {
    isValid: false,
  },
  isValidPostcode: false,
};

AddressPickerUI.propTypes = {
  validatePostcodeField: PropTypes.func.isRequired,
  updatePostcodeField: PropTypes.func.isRequired,
  addressNotListed: PropTypes.bool.isRequired,
  postcode: PropTypes.string,
  addresses: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string.isRequired,
    })
  ),
  isValidForm: PropTypes.shape({
    isValid: PropTypes.bool,
  }),
  isValidPostcode: PropTypes.bool,
  getAddressDetailsRequest: PropTypes.func.isRequired,
  getAddressesRequest: PropTypes.func.isRequired,
  selectedAddressId: PropTypes.string,
  selectAddress: PropTypes.func.isRequired,
  addressesFetching: PropTypes.bool.isRequired,
  addressesError: PropTypes.string,
  isRequestInProgress: PropTypes.bool.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  updateAnalyticsPage: PropTypes.func,
  reset: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  autoFocus: PropTypes.bool,
  showBackButton: PropTypes.bool,
  children: PropTypes.node,
  continueDisabled: PropTypes.bool,
};

export default withStyles(styles)(AddressPickerUI);
