import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useLocation } from 'react-router-dom';

import Typography from '@material-ui/core/Typography';
import TextField from 'modules/Shared/NewTextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Alert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';

import SectionCentered from 'modules/layout/SectionCentered';
import SecurityIcon from '../SecurityIcon';
import styles from './styles';
import * as copy from './copy';

const CustomerFormComponent = ({
  classes,
  error,
  isRemoteSession,
  startCustomerSession,
  isPartnerLoggedIn,
}) => {
  const { search } = useLocation();
  const accessCode = new URLSearchParams(search).get('remote_code');
  const [code, setCode] = React.useState(accessCode);
  const [openedFromCode, setOpened] = React.useState(false);

  const startSession = () => {
    startCustomerSession(code);
  };

  if (
    !openedFromCode &&
    !isRemoteSession &&
    !isEmpty(accessCode) &&
    !isPartnerLoggedIn
  ) {
    setOpened(true);
    startSession();
  }

  if (isPartnerLoggedIn) {
    return (
      <SectionCentered sectionCenteredClasses={{ root: classes.container }}>
        <Alert
          classes={{
            root: classes.alertRoot,
            standardInfo: classes.alertBg,
            message: classes.alertMessage,
          }}
          icon={false}
          variant="standard"
          severity="info"
        >
          <Typography variant="body1" component="p" color="textPrimary">
            {copy.PARTNER_LOGGED_IN}
          </Typography>
        </Alert>
      </SectionCentered>
    );
  }

  return (
    <SectionCentered sectionCenteredClasses={{ root: classes.container }}>
      <form
        className={classes.pos}
        onSubmit={(e) => {
          e.preventDefault();
          startSession();
        }}
      >
        <div className={classes.formContainer}>
          <TextField
            fullWidth
            textFieldClasses={{ form: classes.inputContainer }}
            label={copy.CODE_FIELD_LABEL}
            value={code}
            error={!isEmpty(error)}
            errorMessage={error}
            onChange={(event) => setCode(event.target.value.toUpperCase())}
          />
          <Button
            size="large"
            variant="contained"
            onClick={() => {
              startSession();
            }}
            className={classes.button}
          >
            {copy.START_SESSION_BUTTON}
          </Button>
        </div>
      </form>

      <Typography variant="body1" component="p" color="textPrimary">
        {copy.INSTRUCTION_LABEL}
      </Typography>

      <Alert
        classes={{
          root: classes.alertRoot,
          standardInfo: classes.alertBg,
          message: classes.alertMessage,
        }}
        icon={false}
        variant="standard"
        severity="info"
      >
        <SecurityIcon />
        <Box ml={2}>
          <Box display="inline-block" fontWeight="fontWeightMedium">
            Don't worry,
          </Box>{' '}
          all sensitive information will be obscured from their view when you
          see the icon.
        </Box>
      </Alert>
    </SectionCentered>
  );
};

CustomerFormComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  error: PropTypes.string,
  isRemoteSession: PropTypes.bool.isRequired,
  startCustomerSession: PropTypes.func.isRequired,
  isPartnerLoggedIn: PropTypes.bool.isRequired,
};

export default withStyles(styles)(CustomerFormComponent);
