import React, { useEffect } from 'react';
import useStyles from './styles';

const eventHandler = (onSubmit, onError) => ({ data }) => {
  if (data && data.success) {
    return setTimeout(() => onSubmit(data.token), 1000);
  }

  if (data && data.error) {
    return setTimeout(() => onError(new Error(data.error)), 1000);
  }
};

const SemafoneIframe = ({ url, onSubmit, onError }) => {
  const classes = useStyles();
  useEffect(() => {
    const handler = eventHandler(onSubmit, onError);
    window.addEventListener('message', handler);

    return () => window.removeEventListener('message', handler);
  }, []);

  return <iframe name="semafone" className={classes.iframe} src={url}></iframe>;
};

export default SemafoneIframe;
