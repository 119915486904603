import React from 'react';
import { useStyles } from './styles';
import { useIsMobile } from 'app/components/modules/OrderSummary/ServicesTotal/Save/utils';

function createUniqueIntegerId() {
  return Math.floor(Math.random() * 10e6);
}

const LoadingSvg = () => {
  const classes = useStyles();
  const fillTiming = '0.65,0.05,0.36,1';
  const fillId = `logofill-${createUniqueIntegerId()}`;
  let fillSize = 837;
  let width = 186;
  let height = 87;

  const isMobile = useIsMobile();

  if (isMobile) {
    fillSize = 666;
    width = 148;
    height = 69;
  }

  return (
    <div className={classes.wrapper}>
      {!isMobile && (
        <svg
          width={width}
          height={height}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 186 87.187"
        >
          <path
            className={classes.strokeBg}
            d="M54.354 2.927A9.682 9.682 0 0 0 41.333 15.425l11.398 27.415c2.038 4.902 1.926 9.76 -0.319 13.678 -2.279 3.975 -6.504 6.569 -11.899 7.308 -5.227 0.717 -10.034 -0.573 -13.538 -3.632 -3.654 -3.188 -5.587 -8.069 -5.587 -14.116V11.705a9.685 9.685 0 1 0 -19.366 0v34.375c0 11.637 4.34 21.835 12.222 28.71 6.392 5.579 14.775 8.59 23.582 8.59 1.76 0 3.534 -0.119 5.313 -0.364 11.318 -1.548 20.82 -7.697 26.077 -16.867 5.223 -9.114 5.732 -20.318 1.4 -30.737l-8.367 -19.985a9.682 9.682 0 1 1 17.881 -7.434l16.837 40.486 16.836 -40.486a9.685 9.685 0 0 1 17.883 0l16.834 40.486 16.834 -40.486a9.685 9.685 0 0 1 17.881 7.434L157.457 77.411a9.682 9.682 0 0 1 -17.881 0l-16.836 -40.484 -16.834 40.485a9.685 9.685 0 0 1 -8.94 5.964c-3.913 0 -7.44 -2.352 -8.778 -5.573l-7.203 -17.323"
            fill="none"
            fillRule="evenodd"
          />
          <path
            className={classes.strokeFill}
            id={fillId}
            d="m80.983 60.481 7.203 17.323c1.337 3.221 4.863 5.573 8.778 5.573a9.685 9.685 0 0 0 8.94 -5.964l16.834 -40.487 16.834 40.487a9.685 9.685 0 0 0 17.883 0l25.776 -61.985a9.682 9.682 0 1 0 -17.883 -7.435l-16.833 40.483 -16.834 -40.486a9.685 9.685 0 0 0 -17.883 0l-16.836 40.485L80.128 7.989a9.685 9.685 0 1 0 -17.881 7.434l8.366 19.983c4.332 10.421 3.82 21.625 -1.4 30.737 -5.256 9.171 -14.759 15.318 -26.077 16.869a39.211 39.211 0 0 1 -5.313 0.364c-8.808 0 -17.189 -3.012 -23.582 -8.59C6.362 67.914 2.02 57.716 2.02 46.079V11.706a9.685 9.685 0 1 1 19.366 0v34.375c0 6.045 1.932 10.928 5.587 14.116 3.503 3.057 8.311 4.346 13.538 3.632 5.395 -0.738 9.623 -3.333 11.899 -7.308 2.244 -3.918 2.357 -8.774 0.319 -13.676L41.332 15.425A9.682 9.682 0 0 1 54.354 2.927"
            fill="none"
            fillRule="evenodd"
            strokeDasharray={`${fillSize} ${fillSize * 2}`}
          />
          <animate
            id={fillId}
            attributeName="stroke-dashoffset"
            calcMode="spline"
            values={`${fillSize * 2};${fillSize * 3};${fillSize * 4}`}
            keyTimes="0;0.5;1"
            keySplines={`${fillTiming};${fillTiming}`}
            dur="8s"
            begin="0s"
            repeatCount="indefinite"
          />
        </svg>
      )}

      {isMobile && (
        <svg
          width={width}
          height={height}
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 148 69.375"
        >
          <path
            className={classes.strokeBg}
            d="M43.249 2.329a7.704 7.704 0 0 0 -10.361 9.945L41.958 34.088c1.622 3.901 1.533 7.766 -0.254 10.884 -1.813 3.163 -5.175 5.227 -9.468 5.815 -4.159 0.571 -7.984 -0.456 -10.772 -2.89 -2.907 -2.537 -4.446 -6.42 -4.446 -11.232V9.314a7.706 7.706 0 1 0 -15.41 0V36.666c0 9.26 3.453 17.374 9.725 22.845 5.086 4.439 11.756 6.835 18.764 6.835 1.4 0 2.812 -0.095 4.228 -0.29 9.006 -1.232 16.566 -6.124 20.749 -13.421 4.156 -7.252 4.561 -16.167 1.114 -24.457l-6.658 -15.902A7.704 7.704 0 1 1 63.759 6.36l13.397 32.215 13.396 -32.215a7.706 7.706 0 0 1 14.229 0l13.395 32.215 13.395 -32.215a7.706 7.706 0 0 1 14.228 5.915l-20.512 49.321a7.704 7.704 0 0 1 -14.228 0L97.664 29.383l-13.395 32.214a7.706 7.706 0 0 1 -7.114 4.746c-3.114 0 -5.92 -1.871 -6.985 -4.434L64.44 48.124"
            fill="none"
            fillRule="evenodd"
          />
          <path
            className={classes.strokeFill}
            id="logofill-6097466"
            d="m64.438 48.125 5.731 13.784c1.064 2.563 3.869 4.434 6.985 4.434a7.706 7.706 0 0 0 7.114 -4.746l13.395 -32.215 13.395 32.215a7.706 7.706 0 0 0 14.229 0l20.51 -49.321a7.704 7.704 0 1 0 -14.229 -5.916l-13.394 32.212L104.778 6.358a7.706 7.706 0 0 0 -14.229 0L77.153 38.572 63.758 6.357a7.706 7.706 0 1 0 -14.228 5.915l6.657 15.9c3.447 8.292 3.04 17.207 -1.114 24.457 -4.182 7.297 -11.744 12.189 -20.749 13.423a31.2 31.2 0 0 1 -4.228 0.29c-7.009 0 -13.677 -2.397 -18.764 -6.835C5.062 54.039 1.607 45.925 1.607 36.665V9.314a7.706 7.706 0 1 1 15.41 0v27.352c0 4.81 1.537 8.695 4.446 11.232 2.787 2.432 6.613 3.458 10.772 2.89 4.293 -0.587 7.657 -2.652 9.468 -5.815 1.786 -3.118 1.875 -6.981 0.254 -10.882l-9.069 -21.818A7.704 7.704 0 0 1 43.249 2.329"
            fill="none"
            fillRule="evenodd"
            strokeDasharray={`${fillSize} ${fillSize * 2}`}
          />
          <animate
            id="loadinganim"
            attributeName="stroke-dashoffset"
            calcMode="spline"
            values={`${fillSize * 2};${fillSize * 3};${fillSize * 4}`}
            keyTimes="0;0.5;1"
            keySplines={`${fillTiming};${fillTiming}`}
            dur="8s"
            begin="0s"
            repeatCount="indefinite"
          />
        </svg>
      )}
    </div>
  );
};

export { LoadingSvg };
