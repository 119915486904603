import { makeStyles } from '@material-ui/core/styles';
import { colorsCommon } from '@utilitywarehouse/colour-system';
const useStyles = makeStyles(({ spacing, palette }) => ({
  paper: {
    borderRadius: spacing(1),
    backgroundColor: palette.text.primary,
    padding: spacing(2),
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing(3),
  },
  highlight: {
    color: colorsCommon.brandPink,
  },
}));

export { useStyles };
