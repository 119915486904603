import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import isEmpty from 'lodash/isEmpty';
import { Redirect, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import illustrations from '@utilitywarehouse/partner-ui-assets/lib/js/images/illustrations';

import SectionCentered from 'modules/layout/SectionCentered';
import TextField from 'modules/Shared/NewTextField';
import OrderedNumberList from 'modules/Shared/OrderedNumberList';
import IllustrationSpotlight from 'modules/Shared/IllustrationSpotlight';

import { STEPS, APP_CODE, START_BUTTON, BACK_BUTTON } from './copy';
import useStyles from './styles';

const SupportingForm = ({
  isLoggedIn,
  partnerId,
  partnerName,
  error,
  startSupportingSession,
  remoteDisplayName,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const [code, setCode] = useState();

  if (!isLoggedIn) return <Redirect to="/" />;

  return (
    <SectionCentered>
      <div className={classes.imageContainer}>
        <form
          className={classes.codeContainer}
          onSubmit={(e) => {
            e.preventDefault();
            code &&
              startSupportingSession(
                code,
                remoteDisplayName || partnerName,
                partnerId
              );
          }}
        >
          <OrderedNumberList data={STEPS} />
          <TextField
            textFieldClasses={{ form: classes.inputContainer }}
            label={APP_CODE}
            value={code}
            error={!isEmpty(error)}
            errorMessage={error}
            onChange={(event) => setCode(event.target.value.toUpperCase())}
          />
        </form>
        <IllustrationSpotlight
          ISClasses={{ base: classes.illustrationSpotlight }}
        >
          <img className={classes.stepOneImage} src={illustrations.i3.filled} />
        </IllustrationSpotlight>
      </div>
      <div className={classes.buttonContainer}>
        <Button
          size="large"
          variant="outlined"
          onClick={() => history.push('/')}
          className={classes.button}
        >
          {BACK_BUTTON}
        </Button>
        <Button
          size="large"
          variant="contained"
          onClick={() =>
            startSupportingSession(
              code,
              remoteDisplayName || partnerName,
              partnerId
            )
          }
          className={classes.button}
          disabled={!code}
        >
          {START_BUTTON}
        </Button>
      </div>
    </SectionCentered>
  );
};

SupportingForm.propTypes = {
  partnerId: PropTypes.string,
  partnerName: PropTypes.string,
  error: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  startSupportingSession: PropTypes.func.isRequired,
  remoteDisplayName: PropTypes.string,
};

export default withRouter(SupportingForm);
