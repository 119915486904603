import PropTypes from 'prop-types';
import React from 'react';

import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core';

import {
  SKIP_AP_BUTTON_DESKTOP_LABEL,
  SKIP_AP_BUTTON_MOBILE_LABEL,
} from './copy';

import { SkipNextIcon } from '@utilitywarehouse/partner-ui-icons';

const useStyles = makeStyles((theme) => ({
  button: {
    height: 54,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  desktop: {
    fontWeight: 600,
    display: 'none',
    visibility: 'hidden',
    marginRight: theme.spacing(0.5),
    [theme.breakpoints.up('sm')]: {
      display: 'initial',
      visibility: 'visible',
    },
  },
  mobile: {
    fontWeight: 600,
    marginRight: theme.spacing(0.5),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
      visibility: 'hidden',
    },
  },
}));

const SkipAppointmentPresentationButton = ({ disabled = false, onClick }) => {
  const classes = useStyles();

  return (
    <Button
      disabled={disabled}
      variant="contained"
      onClick={onClick}
      classes={{ root: classes.button }}
      data-test-id="apButtonSkip"
    >
      <Typography classes={{ root: classes.desktop }}>
        {SKIP_AP_BUTTON_DESKTOP_LABEL}
      </Typography>
      <Typography classes={{ root: classes.mobile }}>
        {SKIP_AP_BUTTON_MOBILE_LABEL}
      </Typography>
      <SkipNextIcon />
    </Button>
  );
};

SkipAppointmentPresentationButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default SkipAppointmentPresentationButton;
