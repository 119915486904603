import React from 'react';
import { Typography } from '@material-ui/core';
import SectionCentered from 'modules/layout/SectionCentered';
import { Banner } from './Banner';
import { LoadingSvg } from './LoadingSvg';

import { useStyles } from './styles';

const PreselectLoading = () => {
  const classes = useStyles();

  return (
    <SectionCentered newLayout>
      <div className={classes.container}>
        <LoadingSvg />
        <Typography variant="h2" component="h1" className={classes.headerTitle}>
          Just a moment while we put together your quote...
        </Typography>
        <Banner />
      </div>
    </SectionCentered>
  );
};

PreselectLoading.propTypes = {};

export { PreselectLoading };
