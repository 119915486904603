import { makeStyles } from '@material-ui/core/styles';
import { getBreakpoints, getSpacing } from 'app/lib/theme';

export default makeStyles((theme) => {
  const spacing = getSpacing(theme);
  const breakpoints = getBreakpoints(theme);

  return {
    imageContainer: {
      alignItems: 'center',
      display: 'flex',
    },
    illustrationSpotlight: {
      display: 'none',
      marginLeft: spacing(4),
      width: '50%',
      [breakpoints.up('md')]: {
        display: 'block',
      },
    },
    stepOneImage: {
      maxWidth: '80%',
    },
    inputContainer: {
      display: 'flex',
      flex: 0.48,
      marginTop: spacing(2),
      marginBottom: spacing(2),
      [breakpoints.up('sm')]: {
        maxWidth: '50%',
      },
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      marginBottom: spacing(2),
      [breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    codeContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    button: {
      height: '100%',
      marginTop: spacing(1),
    },
  };
});
