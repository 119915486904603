import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import clsx from 'classnames';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Snackbar from '@material-ui/core/Snackbar';
import Typography from '@material-ui/core/Typography';

import { Actor, ActorType } from 'redux/modules/RemoteSession/v2/actor';
import SnackbarContent from 'modules/Shared/SnackbarContent';
import Controls from './Controls';
import AcceptConnectionDialog from './Dialogs/AcceptConnection';
import useStyles from './styles';

const useDialog = (defaultState = false) => {
  const [state, setState] = React.useState(defaultState);

  return {
    isOpen: state,
    open: () => {
      setState(true);
    },
    close: () => {
      setState(false);
    },
  };
};

const RemoteSessionOverlay = ({
  remoteSession,
  hasSensitiveContent,
  requestControl,
  requestFromName,
  isRequestControlDialogOpen,
  onAcceptRequestControl,
  onDismissRequestControl,
  onEndSessionDialogConfirm,
  isConfirmConnectionDialogOpen,
  onAcceptConnectionDialogReject,
  onAcceptConnectionDialogAccept,
  connectionFrom,
  roster,
  isInfoSnackbarOpen,
  infoSnackbarMessage,
  onInfoSnackbarClose,
  hasRemoteStarted,
}) => {
  const classes = useStyles();
  const endSessionDialog = useDialog();

  if (!hasRemoteStarted) return null;

  const { local, driver: driverType } = remoteSession;
  const actor = new Actor({ type: local.type });
  actor.status = local.status;

  return (
    <Fragment>
      <Box
        position="fixed"
        zIndex="modal"
        top={0}
        bottom={0}
        left={0}
        right={0}
        border={6}
        style={{ pointerEvents: 'none' }}
        classes={{
          root: clsx({
            [classes.borderCustomer]: driverType === ActorType.CUSTOMER,
            [classes.borderPartner]: driverType === ActorType.PARTNER,
            [classes.borderSupporting]:
              driverType === ActorType.SUPPORTING_PARTNER,
          }),
        }}
      ></Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={isInfoSnackbarOpen}
        autoHideDuration={5000}
        onClose={onInfoSnackbarClose}
        classes={{ root: classes.infoSnackbar }}
      >
        <SnackbarContent
          message={
            <Typography classes={{ root: classes.message }} variant="subtitle2">
              {infoSnackbarMessage}
            </Typography>
          }
        />
      </Snackbar>
      <Snackbar
        open={true}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Controls
          participants={{
            ...roster,
            [remoteSession.local.type]: {
              ...remoteSession.local,
            },
          }}
          driver={driverType}
          actor={actor.type}
          onEndSession={endSessionDialog.open}
          onRequestControl={requestControl}
          securePage={hasSensitiveContent}
        />
      </Snackbar>
      <Dialog
        open={endSessionDialog.isOpen}
        onClose={endSessionDialog.close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Remote Appointment</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to end this appointment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onEndSessionDialogConfirm();
              endSessionDialog.close();
            }}
            color="primary"
            autoFocus
          >
            Yes, end appointment
          </Button>
          <Button
            variant="contained"
            onClick={endSessionDialog.close}
            color="primary"
          >
            No, continue
          </Button>
        </DialogActions>
      </Dialog>
      <AcceptConnectionDialog
        open={isConfirmConnectionDialogOpen}
        actor={actor}
        connectionFrom={connectionFrom}
        onDeny={onAcceptConnectionDialogReject}
        onAccept={onAcceptConnectionDialogAccept}
      />
      <Dialog
        open={isRequestControlDialogOpen && requestFromName}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Control request</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {requestFromName} wants to take control
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={onDismissRequestControl}>
            No, keep control
          </Button>
          <Button
            autoFocus
            color="primary"
            onClick={onAcceptRequestControl}
            variant="contained"
            data-test-id="controlRequestApprove"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

RemoteSessionOverlay.propTypes = {
  remoteSession: PropTypes.shape({
    mainPeer: PropTypes.string,
    local: PropTypes.shape({
      type: PropTypes.string,
      status: PropTypes.string,
      isDriver: PropTypes.bool,
    }),
    remote: PropTypes.shape({
      type: PropTypes.string,
      status: PropTypes.string,
      name: PropTypes.string,
    }),
    error: PropTypes.any,
    driver: PropTypes.string,
  }).isRequired,
  isInfoSnackbarOpen: PropTypes.bool,
  infoSnackbarMessage: PropTypes.string,
  onInfoSnackbarClose: PropTypes.func.isRequired,
  isRequestControlDialogOpen: PropTypes.bool.isRequired,
  requestFromName: PropTypes.string,
  hasSensitiveContent: PropTypes.bool.isRequired,
  onEndSessionDialogConfirm: PropTypes.func.isRequired,
  onAcceptRequestControl: PropTypes.func.isRequired,
  onDismissRequestControl: PropTypes.func.isRequired,
  isConfirmConnectionDialogOpen: PropTypes.bool.isRequired,
  onAcceptConnectionDialogReject: PropTypes.func.isRequired,
  onAcceptConnectionDialogAccept: PropTypes.func.isRequired,
  connectionFrom: PropTypes.string,
  roster: PropTypes.objectOf(PropTypes.object).isRequired,
  hasRemoteStarted: PropTypes.bool.isRequired,
  requestControl: PropTypes.func.isRequired,
};

export default RemoteSessionOverlay;
