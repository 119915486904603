import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import TextField from 'modules/CustomerUI/TextField';

const PostcodeInput = ({
  onChange,
  postcode = '',
  error,
  errorMessage,
  ...props
}) => {
  const handlePostcodeChanged = useCallback(
    (e) => {
      const value = e.target.value;
      onChange(value.toUpperCase());
    },
    [onChange]
  );

  return (
    <TextField
      label="Your postcode"
      placeholder="Search for your postcode"
      fullWidth
      onChange={handlePostcodeChanged}
      value={postcode}
      error={error}
      errorMessage={errorMessage}
      id={`Postcode`}
      {...props}
    />
  );
};

PostcodeInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  postcode: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
};

export default PostcodeInput;
