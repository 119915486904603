import { makeStyles } from '@material-ui/core/styles';
import { colorsCommon } from '@utilitywarehouse/colour-system';

export default makeStyles((theme) => ({
  holder: {
    marginBottom: theme.spacing(4),
  },
  inputHolder: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 400,
    '& label.uwu-Label.uwu-Typography': {
      fontWeight: 600,
      fontSize: 16,
      color: colorsCommon.brandMidnight,
    },
    '& div:where(:not(#numberErrorTextParent):not(#cvvErrorTextParent)) > p': {
      // to override inline styles
      fontSize: '16px !important',
      color: colorsCommon.brandMidnight,
    },
    '& div#cvvErrorTextParent > p, & .uwu-HelperText, & div#numberErrorTextParent > p': {
      fontSize: 13,
    },
    '& input': {
      fontSize: 16,
    },
  },
}));
