export default () => ({
  addressLines: {
    paddingTop: 5,
    paddingBottom: 10,
  },
  addressCopy: {
    paddingRight: 50,
  },
  addressContainer: {
    display: 'flex',
  },
  lines: {
    display: 'block',
  },
});
