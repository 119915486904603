import Media from 'constants/media.js';

export default (theme) => ({
  base: {
    maxWidth: '100%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: 430,
    },
  },
  SelectFieldUI: {
    display: 'flex',
    outline: 'none',
    overflow: 'hidden',
    marginTop: theme.spacing(2),
  },
  fieldWrapper: {
    display: 'flex',
    [Media.TABLET]: {
      flexDirection: 'column',
    },
    [Media.LAPTOP_PLUS]: {
      flexDirection: 'row',
    },
  },
  errorMessage: {
    backgroundColor: theme.palette.error.main,
  },
  buttonCol: {
    [Media.LAPTOP_PLUS]: {
      width: 200,
    },
  },
  buttonWrapper: {
    marginTop: 20,
    [Media.MOBILE_M]: {
      display: 'flex',
    },
  },
  button: {
    display: 'block',
    width: '100%',
  },
  linearProgress: {
    marginTop: 4,
  },
});
