import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@material-ui/core/styles';
import { ActorType } from 'redux/modules/RemoteSession/v2/actor';
import Cursor from './Cursor';
import { useStyles } from './styles';

export const getRemoteCursorId = (actorType) => {
  return `REMOTE_CURSOR_ID_${actorType}`;
};

const getActorBadge = (actorType, name) => {
  switch (actorType) {
    case ActorType.CUSTOMER:
      return 'Prospect';
    case ActorType.PARTNER:
      return name || 'Partner';
    case ActorType.SUPPORTING_PARTNER:
      return name || 'Supporting partner';
    default:
      return '';
  }
};

const RemoteCursor = ({ actorType, name }) => {
  const classes = useStyles();
  const theme = useTheme();
  const getColor = () => {
    if (actorType === ActorType.CUSTOMER) return theme.palette.secondary.main;
    if (actorType === ActorType.PARTNER) return theme.palette.success.main;
    if (actorType === ActorType.SUPPORTING_PARTNER)
      return theme.palette.services.landline;
  };
  const color = getColor();

  return (
    <div id={getRemoteCursorId(actorType)} className={classes.container}>
      <Cursor color={color} className={classes.cursorIcon} />
      <div className={classes.cursorLabel} style={{ backgroundColor: color }}>
        {getActorBadge(actorType, name)}
      </div>
    </div>
  );
};

RemoteCursor.propTypes = {
  actorType: PropTypes.oneOf(Object.values(ActorType)).isRequired,
  name: PropTypes.string,
};

export default RemoteCursor;
